import Head from 'next/head';

import styles from '../styles/Home.module.css';

import type { NextPage } from 'next';

// eslint-disable-next-line @typescript-eslint/naming-convention
const Home: NextPage = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>Welcome to Summer Solstice App</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>Welcome to Summer Solstice App</h1>
      </main>
    </div>
  );
};

export default Home;
